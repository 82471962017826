<template>
    <main>
        <div class="card-header">
            <div class="row">
          <div class="col-6 ">
            Apgar 
          </div>
          <div class="col-6 d-flex justify-content-end">
            <router-link v-if="_can('card.apgar')"
              :to="{ name: 'usuario.apgar', params: { id: idUsuario } }"
              class="btn btn-primary btn-xs"
            >
              Diligenciar <i class="fas fa-plus fa-fw"></i>
            </router-link>
          </div>
        </div>
        </div>
        <div class="card-body">
            <span class="d-block small">Ultima Valoración : 
                <span v-if="Object.keys(apgar).length">{{apgar.fecha | formatDate }}</span>
                <span v-else class="text-danger">Sin datos...</span>
            </span>
            <span class="d-block small">Puntaje Total : 
                <span v-if="Object.keys(apgar).length">{{apgar.puntaje_total}}</span>
                <span v-else class="text-danger">Sin datos...</span>
            </span>                  
            <span :class="clasificacion_apgar.class">{{clasificacion_apgar.valoracion}}</span>
            <span>
                <router-link
                :to="{ name: 'usuario.apgar.historial', params: { id: idUsuario } }"
                class="btn btn-info btn-xs"
                >
                Ver Historial  <i class="fas fa-history fa-fw"></i>
                </router-link>
            </span>                  
        </div>
    </main>
</template>
<script>

import valoracionApgarFamiliarService from '../../../../services/valoracionApgarFamiliarService';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';

export default {
    props: ['idUsuario', 'usuario'],
    data(){
        return {
            apgar:{},
        }
    },
    watch: {
        idUsuario : async function(){
            const response = await valoracionApgarFamiliarService.showLast(this.idUsuario);
            this.apgar= response.data;
        }
    },
    computed: {
        edad : function(){
            if(isEmpty(this.usuario)) return 0;
            return dayjs().diff(this.usuario.fecha_nacimiento, 'year');
        },
        clasificacion_apgar(){
            
            const clasficicacion_ninos = [
                {
                    inicio : 7,
                    fin : 10,
                    valoracion : 'Funcionalidad Normal',
                    class : 'badge badge-success'
                },
                {
                    inicio : 4,
                    fin : 6,
                    valoracion : 'Disfunción Moderada',
                    class : 'badge badge-warning'
                },
                {
                    inicio : 0,
                    fin : 3,
                    valoracion : 'Disfunción Grave',
                    class : 'badge badge-danger'
                }
            ];

            if(this.edad <= 18){
                const clasificacion = clasficicacion_ninos.find(x => this.apgar.puntaje_total >= x.inicio && this.apgar.puntaje_total <= x.fin);
                if(clasificacion) return clasificacion 
            }

            return {
                inicio : 0,
                fin : 0,
                valoracion : 'Cargando clasificacion',
                class : 'span span-default text-danger'
            };
        },
        tiempo_ultimo_apgar (){
            if(isEmpty(this.apgar)) return 0;
            return dayjs().diff(this.apgar.fecha, 'year');
        }
        
    }
}
</script>